import React, {useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import tableStyles from "../../../tech/table/table.module.css"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {InvestorWithDetailsType} from "../investor.type"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {
    INVESTMENTS_OF_INVESTOR,
    INVESTOR,
    INVESTOR_KYC_LOGS,
    INVESTOR_NEW,
    INVESTOR_SUBSCRIPTION_LOGS
} from "../../../paths"
import ActionLink from "../../../tech/link/action.component"
import Actions from "../../../tech/actions/actions.component"
import {emptyPaginated, getPagination} from "../../../tech/actions/pagination/pagination.util"
import {PaginatedType} from "../../../tech/actions/pagination/pagination.type"
import {assembleSortOptions, getCurrentSortOption} from "../sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToInvestorFiltersOpenApi} from "../filter.util"
import SubscriptionSummary from "../../subscription/summary.component"
import InvestorKycTableCell from "../kyc/kyc-table-cell.component"
import styles from "./overview.module.sass"
import {formatDatetime} from "../../../tech/format/format.util"

const InvestorOverview = () => {
    const INVESTORS_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        investors,
        setInvestors
    ] = useState<PaginatedType<InvestorWithDetailsType>>(emptyPaginated)

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(INVESTORS_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                setInvestors(await fetchClient.investorApi.getAllPaginated(
                    currentSortOption.id,
                    mapToInvestorFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Investors"
                subTitle={`${investors.total} investors`}
                button={<Button
                    title="Create new investor for user"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(INVESTOR_NEW)}
                    type={"button"}
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "investors",
                    elementsPerPage: INVESTORS_PER_PAGE,
                    numberElements: investors.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Created</th>
                                <th>Deal groups</th>
                                <th>Investor groups</th>
                                <th>KYC</th>
                                <th>Subscription</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {investors.elements.map((investorWithDetails, i) => {
                                const l = () => navigate(INVESTOR(investorWithDetails.investor.id!))
                                return (
                                    <tr key={i}>
                                        <td onClick={l}>
                                            {investorWithDetails.investor.person?.fullName}
                                        </td>
                                        <td onClick={l}>
                                            {investorWithDetails.investor.person?.contact?.email}
                                        </td>
                                        <td onClick={l}>
                                            {formatDatetime(investorWithDetails.investor.created)}
                                        </td>
                                        <td onClick={l}>
                                            <small>
                                                {investorWithDetails.investor.dealGroups.length > 0
                                                    ? investorWithDetails.investor.dealGroups.map(g => g.name).join(", ")
                                                    : <i>none</i>
                                                }
                                            </small>
                                        </td>
                                        <td onClick={l}>
                                            <small>
                                                {investorWithDetails.investor.investorGroups.length > 0
                                                    ? investorWithDetails.investor.investorGroups.map(g => g.name).join(", ")
                                                    : <i>none</i>
                                                }
                                            </small>
                                        </td>
                                        <InvestorKycTableCell
                                            investor={investorWithDetails.investor}
                                            onClick={l}
                                        />
                                        <td onClick={l}>
                                            <SubscriptionSummary subscription={investorWithDetails.subscription}/>
                                        </td>
                                        <td className={styles.actions}>
                                            <ActionLink
                                                text={"Investments"}
                                                link={INVESTMENTS_OF_INVESTOR(investorWithDetails.investor.id!)}
                                            />
                                            <ActionLink
                                                text={"KYC Logs"}
                                                link={INVESTOR_KYC_LOGS(investorWithDetails.investor.id!)}
                                            />
                                            <ActionLink
                                                text={"Subscription Logs"}
                                                link={INVESTOR_SUBSCRIPTION_LOGS(investorWithDetails.investor.id!)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load investors"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default InvestorOverview